* {
  text-rendering:optimizeLegibility;
}

html {
  height: 100%;
  padding-bottom: 70px;
  overflow: hidden;
  max-width: 100vw;
}

body, #root, .ls {
  height: inherit;
  max-width: 100%;
}

.ls.debug, .ls.demographics, .ls.index {
  overflow: auto;
}

body {
  font-family: "Source Sans Pro", sans-serif;
}

.studyCont img:not(.instructionImage):not(.ls-shelfHidden):not(.ls-zoomResultImg), .video-react {
  max-width: 100% !important;
}

.studyCont img:not(.instructionImage):not(.ls-shelfHidden):not(.ls-zoomResultImg), .ls-scrollCont {
  max-height: calc(100% - 20px) !important;
}

.video-react {
  max-height: calc(100% - 20px) !important;
}

.video-react-video {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  max-height: 100% !important;
  max-width: 100% !important;
  width: auto !important;
  height: auto !important;
}

#dark2 img {
  transform: none;
}

a, a:not([href]):not([tabindex]), a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover, .link {
  color: var(--ui-primary-color);
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}

a:hover, a:not([href]):not([tabindex]):hover, .link:hover {
  cursor: pointer;
}

p, a {
  font-size: 1.2rem;
}

button, .btn, .btn-primary {
  background-color: var(--ui-primary-color);
  border-color: var(--ui-primary-color);
  color: var(--ui-text-color);
}

button:hover, .btn:hover, .btn-primary:hover, .btn-primary:active {
  background-color: var(--ui-secondary-color);
  border-color: var(--ui-secondary-color);
  color: #FFF;
}

textarea, input {
  border: 1px solid var(--ui-primary-color);
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #8e8e8e;
    border-color: #8e8e8e;
}

/* Currently hidden when disabled - footer only */
.ls-footer .btn-primary.disabled, .ls-footer .btn-primary:disabled {
  display: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.button-primary {
  color: var(--ui-text-color);
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 600;
  margin-top: 4px;
  position: relative;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  background-color: var(--ui-secondary-color);
  border-color: var(--ui-secondary-color);
}

.button-secondary {
  color: var(--ui-text-color);
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 600;
  margin-top: 4px;
  position: relative;
}

.start-button {
  background-color: #26b13f;
  border-color: #26b13f;
}

.start-button:hover, .start-button:active, .start-button:focus {
  background-color: #187229;
  border-color: #187229;
}

.invalidate-button {
  background-color: #b12653;
  border-color: #b12653;
}

.invalidate-button:hover, .invalidate-button:active, .invalidate-button:focus {
  background-color: #721835;
  border-color: #721835;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

.progress {
  border-radius: 0;
  height: 4px;
}

.progress-bar {
  background-color: var(--ui-primary-color);
}

.block {
  display: block;
}

.absoluteCenter {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.centerContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.centerAlign {
  text-align: center;
}

.rightAlign {
  text-align: right;
}

.floatRight {
  float: right;
}

.logo {
  width: 100%;
  max-width: 200px;
  min-width: 150px;
}

.ls-header {
  background: var(--primary-color);
  height: 70px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  margin-bottom: 20px;
  z-index: 2;
  position: relative;
}

.menu {
  font-size: 2.5rem;
  color: var(--compliment-color);
  margin: 14px 0;
}

.menu:hover {
  cursor: pointer;
}

.menu-cont {
  position: absolute;
}

.ls-header .menu-cont button {
  float: left;
  clear: both;
  margin-bottom: 10px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  text-transform: uppercase;
}

.ls-footer {
  background: var(--primary-color);
  height: 70px;
  box-shadow: 0px -3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  position: fixed;
  bottom: 0;
  width: 100%;
}

.ls-footer .menu-cont {
  transform: translateY(-100%) translateY(-70px);
}

.ls-footer .menu-cont button {
  float: left;
  clear: both;
  margin-bottom: 10px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  text-transform: uppercase;
}

.ls-main {
   text-align: center;
   position: relative;
}

.ls-loading {
  z-index: -1;
}

.help-cont {
  width: 100%;
  height: 100%;
  max-height: 68px;
  position: relative;
  cursor: pointer;
  top: 2px;
}

.obscure {
  width: 100%;
  height: 100%;
  position: relative;
  background: var(--primary-color);
  max-height: 70px;
  top: -2px;
}

.help-tag {
  color: white;
  width: 50%;
  text-transform: uppercase;
  text-align: center;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  background: var(--ui-primary-color); /* Old browsers */
  /*
  background: -moz-linear-gradient(top, var(--primary-color) 0%, var(--ui-primary-color) 100%);
  background: -webkit-linear-gradient(top, var(--primary-color) 0%,var(--ui-primary-color) 100%);
  background: linear-gradient(to bottom, var(--primary-color) 0%,var(--ui-primary-color) 100%);
  */
  z-index: -1;
  padding: 3px;
  border-radius: 0 0 5px 5px;
  position: absolute;
  right: 10px;
  margin-top: -42px;
  transition: all 0.3s ease-in-out;
  font-size: 2rem;
  font-weight: 600;
}

.help-tag.help-tag-active {
  margin-top: -2px;
}

.ls-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2147483647;
}

.ls-modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
  background: white;
  padding: 50px;
  border: 5px solid var(--ui-primary-color);
}

.ls-modalbg {
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.5;
  filter: blur(50px);
}

.ls-modalclose {
  font-size: 30px;
  position: absolute;
  right: -32px;
  top: -30px;
  background: white;
  padding: 10px 14px;
  border: 5px solid var(--ui-primary-color);
  border-radius: 50px;
}

.ls-modalclose {
  cursor: pointer;
}

.ls-modalContentWrap {
  overflow-y: auto;
  max-height: 316px;
  overflow-x: hidden;
}

.sliderImg {
  margin: 20px 0;
  max-width: 300px;
  display: inline-block !important;
}

.slick-slide {
  text-align: center;
}

.slick-next, .slick-prev {
  top: 30%;
  width: 50px;
  height: 50px;
  z-index: 2;
}

.slick-prev {
  left: 0px;
}

.slick-next {
  right: 0px;
}

.slick-prev:before, .slick-next:before {
  color: grey;
  font-size: 50px;
}

.slick-prev:before {
  content: "\2190";
}

.slick-next:before {
  content: "\2192";
}

.slick-dots li button:before {
  content: "\2022";
}

.finalInstructionSlick {
  margin-top: 50px;
}

.setupCont, .studyCont {
  max-height: calc(100% - 90px);
  height: 100%;
  /* position: relative; */
}

.studyCont > .row, .studyCont > .row > .col-12, .studyCont .ls-main {
  height: 100%;
}

.studyCont img:not(.instructionImage):not(.ls-shelfHidden):not(.ls-zoomResultImg):not(.ls-scrollImg), .studyCont .video-react, .ls-scrollCont {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.studyCont #calibrationElement #arrimg {
  position: fixed;
  left: 50%;
  top: calc(50% - 80px);
}

.ls-shelfCont {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 20px) !important;
  max-width: 100% !important;
  /* height: 100%; */
}

.ls-shelfHidden {
  visibility: hidden;
  position: relative;
  left: auto;
  top: auto;
  transform: none;
  object-fit: scale-down;
  max-height: 100% !important;
}

.ls-lumenShelfAoi {
  background: rgba(0, 0, 0, 0.1);
}

.ls-lumenShelfAoi:hover, .ls-lumenShelfAoi:focus {
  background: rgba(0, 0, 0, 0.3);
}

.ls-imgZoomLens {
  position: absolute;
  border: 1px solid #d4d4d4;
  visibility: hidden;
}

.ls-zoomSrc {
  z-index: 4;
}

.ls-zoomResult {
  border: 1px solid #d4d4d4;
  position: absolute;
  overflow: hidden;
  top: 0;
  visibility: hidden;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 3;
}

.ls-zoomResultImg {
  position: absolute;
}

.studyCont .video-react {
  background: #FFF;
}

.studyCont pre {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.5rem;
  overflow: hidden;
  white-space: pre-wrap;
}

.studyCont pre b {
  font-weight: 900;
}

.studyCont .lumenIframeStandard {
  width: 100vw;
  height: calc(100% + 20px);
  border: none;
  margin-top: -20px;
  position: absolute;
  transform: translate(-50%);
  display: inline;
  visibility: hidden; /* made visible in lumen-common */
}

.instructionRow {
  margin-top: 50px;
}

.instructionImage {
  max-width: 100%;
}

#calibrationElement {
  z-index: 200;
  width: 100% !important;
  height: 100% !important;
}

#loader_calib {
  position: absolute;
}

/* TRANSITIONS and ANIMATIONS */

.toggle-fade-enter {
  opacity: 0.01;
}

.toggle-fade-enter.toggle-fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.toggle-fade-leave {
  opacity: 1;
}

.toggle-fade-leave.toggle-fade-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

@keyframes lds-ripple {
  0% {
    top: 92px;
    left: 92px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 16px;
    left: 16px;
    width: 152px;
    height: 152px;
    opacity: 0;
  }
}
@-webkit-keyframes lds-ripple {
  0% {
    top: 92px;
    left: 92px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 16px;
    left: 16px;
    width: 152px;
    height: 152px;
    opacity: 0;
  }
}
.lds-ripple {
  position: relative;
  display: inline-block;
}
.lds-ripple div {
  box-sizing: content-box;
  position: absolute;
  border-width: 8px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: lds-ripple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  animation: lds-ripple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(1) {
  border-color: var(--primary-color);
}
.lds-ripple div:nth-child(2) {
  border-color: var(--ui-primary-color);
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.lds-ripple {
  width: 200px !important;
  height: 200px !important;
  -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
  transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
}

.questionRow {
  text-align: left;
  overflow: auto;
  height: 100%;
}

.questionRow form {
  width: 100%;
  margin-bottom: 20px;
}

.questionRow form div {
  text-align: left;
}

.questionRow input:not([type="text"]) {
    margin-right: 10px;
    top: 50%;
    position: absolute;
    transform: translateY(-66%);
    height: 15px;
}

.questionRow textarea, .questionRow input[type="text"] {
  width: 100%;
  max-width: 400px;
  margin-top: 10px;
}

.questionRow textarea {
  min-height: 100px;
}

.questionRow .radioLabel {
    width: calc(100% - 23px);
    margin-left: 24px;
    font-size: 1.05rem;
}

.questionRow .radioAnswerCont, .questionRow .checkboxAnswerCont {
    position: relative;
    margin-bottom: 10px;
}

.questionRow .questionError {
  color: #dc3545;
}

.questionCont {
  transition: visibility 0s, opacity 0.5s linear;
}

.questionCont:not(.active) {
  visibility: hidden;
  opacity: 0;
  height: 0;
  position: fixed;
}

.btn-question {
  margin-bottom: 20px;
}

/* Responsive Styles */

@media (min-width: 813px), (min-height: 421px){
  .mobileOnly {
    display: none;
  }
}

@media (max-width: 812px), (max-height: 420px) {
  .ls-header {
    margin-top: -50px;
  }
  .setupCont, .studyCont {
    max-height: calc(100% - 40px);
  }
  .desktopOnly {
    display: none;
  }
  .ls-modal {
    width: calc(100% - 60px);
    padding: 20px;
  }
  .ls-modalclose {
    font-size: 20px;
    right: -29px;
  }
  .sliderImg {
    max-width: 150px;
  }
  .instructionRow {
    margin-top: 10px;
  }
  .instructionImage {
    margin-bottom: 20px;
  }
  .progress {
    position: absolute;
    top: 70px;
    width: 100%;
  }
  .logo {
    display: none;
  }
  .questionRow input[type=checkbox], .questionRow input[type=radio] {
    transform: translateY(-66%) scale(1.5);
  }
  .questionRow .radioAnswerCont, .questionRow .checkboxAnswerCont {
    border: 1px solid var(--ui-primary-color);
    padding: 10px;
  }
}

@media (max-width: 812px) {
  #calibrationElement #instimg, #validationElement #instimg2 {
    transform: translateX(-50%);
    left: 50%;
    max-width: 100%;
    top: calc(50% - 250px);
  }
}

/* Bootsrap: xs */
@media (max-width: 575px) {
  .instructionImageDefault {
    display: none;
  }
}

/* Landscape */
@media (max-height: 420px) {
  .ls-footer {
    height: 50px;
  }
  .menu {
    margin: 5px 0;
  }
  .btn-primary {
    font-size: 1.3rem;
    margin: 3px 0;
  }
  .ls-footer .menu-cont button {
    font-size: 1em;
    margin-bottom: 6px;
  }
  #positioningInstructions {
    bottom: 50px !important;
    top: auto !important;
  }
  #instimg, #instimg2 {
    top: 10px !important;
  }
  #tapimg, #tapimg2 {
    top: auto !important;
    bottom: 10px !important;
  }
  .setupCont .instructionsText {
    margin-top: 50px;
  }
  .studyCont pre {
    font-size: 1.2rem;
  }
  .lds-ripple {
    width: 100px !important;
    height: 100px !important;
    transform: translate(-50px, -50px) scale(0.5) translate(50px, 50px);
  }
  .ls-loading h1 {
    font-size: 2rem;
  }
}

@media (max-height: 644px) {
  body {
    line-height: 1;
  }
  .sliderImg {
    max-width: 150px;
  }
}

@media (max-height: 375px) {
  .sliderImg {
    max-width: 70px;
  }
}
